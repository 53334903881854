import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo
} from 'react';
import { PageLoader } from '@instech/components';
import { useClaims } from '../services/managementService';

export interface User {
    name?: string;
    email?: string;
}

// Check and slide user session every 2min
const slideUserSessionIntervalMs = 120000;

const login = () => {
  const returnUrl = window.location.pathname + window.location.search;
  // Login and make sure we get back to the current page
  // Use window, instead of navigate, to have it hit the backend
  // instead of the SPA route
  window.location.href = `/management/login?returnUrl=${encodeURIComponent(returnUrl)}`;
};

interface AuthenticationContext {
    isAuthenticated: boolean;
    user: User | null;
    logout: () => void;
    login: () => void;
}

const defaultContextValue: AuthenticationContext = {
  isAuthenticated: false,
  user: null,
  logout: () => {
    throw new Error('Missing authentication provider');
  },
  login
};

const authenticationContext = createContext<AuthenticationContext>(defaultContextValue);

export const AuthenticationProvider = ({ children }: { children?: ReactNode }) => {
  const { data, initializing, slideUserSession } = useClaims();

  const [isAuthenticated, name, email, bffLogoutUrl] = useMemo(
    () => [
      !!data?.isAuthenticated,
      data?.claims?.find((i) => i.type === 'name')?.value,
      data?.claims?.find((i) => i.type === 'email')?.value,
      data?.claims?.find((i) => i.type === 'bff:logout_url')?.value,
    ],
    [data]
  );

  // We only want a new user object when there is an actual change in its contents,
  // as otherwise get a full page loader on slide user session (due to other useEffects triggered by the user object)
  const user = useMemo((): User | null => (isAuthenticated ? { name, email } : null), [isAuthenticated, name, email]);

  const logout = useCallback(() => {
    if (!bffLogoutUrl) throw new Error('Missing bff:logout_url claim required for logout');
    window.location.href = bffLogoutUrl;
  }, [bffLogoutUrl]);

  const value = useMemo((): AuthenticationContext => ({ isAuthenticated, user, login, logout }), [isAuthenticated, user, logout]);

  useEffect(() => {
    // Keep login session alive while page is open (cookie has 1h sliding expiry)
    const intervalId = setInterval(slideUserSession, slideUserSessionIntervalMs);
    return () => clearInterval(intervalId);
  }, [slideUserSession]);

  if (initializing) return <PageLoader />;

  return (
    <authenticationContext.Provider value={value}>
      {children}
    </authenticationContext.Provider>
  );
};

export const useAccount = () => useContext<AuthenticationContext>(authenticationContext);
